import React, { useState, useRef, useEffect } from "react";
import { Loader2, SquarePen } from "lucide-react";
import { Input } from "../shadcn-components/input";
import { Button } from "../shadcn-components/button";

interface EditableInputProps {
  label: string;
  value: string;
  onSave: (value: string) => void;
}

const EditableInput: React.FC<EditableInputProps> = ({
  label,
  value,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const isSavingRef = useRef(false);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const handleEdit = () => {
    console.log("Entering edit mode");
    setIsEditing(true);
  };

  const handleSave = () => {
    if (isSavingRef.current) return;
    isSavingRef.current = true;
    console.log("Saving:", inputValue);
    setIsEditing(false);
    onSave(inputValue);
    inputRef.current?.blur();
    setTimeout(() => {
      isSavingRef.current = false;
    }, 100);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    console.log("Key pressed:", event.key);
    if (event.key === "Enter") {
      event.preventDefault();
      handleSave();
    }
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    console.log("Button clicked, isEditing:", isEditing);
    if (isEditing) {
      handleSave();
    } else {
      handleEdit();
    }
  };

  const handleBlur = () => {
    console.log("Input blurred");
    if (isEditing && !isSavingRef.current) {
      handleSave();
    }
  };

  return (
    <div className="tw-w-full">
      <label className="tw-block tw-text-sm tw-font-medium tw-text-grey tw-mb-1 tw-text-left">
        {label}
      </label>
      <div className="tw-relative">
        <Input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="tw-pr-10 tw-rounded-full tw-border-gray-300 tw-w-full"
          disabled={!isEditing}
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        <Button
          className="tw-absolute tw-right-[1px] tw-top-[1px]"
          variant="link"
          size="icon"
          onClick={handleButtonClick}
          disabled={false}
        >
          {false ? (
            <Loader2 className="tw-h-4 tw-w-4 tw-text-gray-500 tw-animate-spin" />
          ) : (
            <SquarePen className="tw-h-4 tw-w-4 tw-text-black" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default EditableInput;
