export const countries = [
    {
		id: 101,
		name: "India",
		iso3: "IND",
		iso2: "IN",
		numeric_code: "356",
		phone_code: "91",
		capital: "New Delhi",
		currency: "INR",
		currency_name: "Indian rupee",
		currency_symbol: "₹",
		tld: ".in",
		native: "भारत",
		region: "Asia",
		region_id: "3",
		subregion: "Southern Asia",
		subregion_id: "14",
		nationality: "Indian",
		timezones: [
			{
				zoneName: "Asia/Kolkata",
				gmtOffset: 19800,
				gmtOffsetName: "UTC+05:30",
				abbreviation: "IST",
				tzName: "Indian Standard Time",
			},
		],
		translations: {
			kr: "인도",
			"pt-BR": "Índia",
			pt: "Índia",
			nl: "India",
			hr: "Indija",
			fa: "هند",
			de: "Indien",
			es: "India",
			fr: "Inde",
			ja: "インド",
			it: "India",
			cn: "印度",
			tr: "Hindistan",
		},
		latitude: "20.00000000",
		longitude: "77.00000000",
		emoji: "🇮🇳",
		emojiU: "U+1F1EE U+1F1F3",
	}
];