import { FC } from "react";
import { Button } from "../shadcn-components/button";
import { allInterests } from "../utils/interests-utils";

interface InterestsProps {
  selectedInterests: string[];
  onInterestsChange: (interests: string[]) => void;
  layoutType?: "grid" | "horizontal";
  maxSelectedCount?: number;
}

export const InterestButtons: FC<InterestsProps> = ({
  selectedInterests,
  onInterestsChange,
  layoutType = "grid",
  maxSelectedCount = 5,
}) => {
  const toggleInterest = (interestName: string) => {
    if (selectedInterests.includes(interestName)) {
      onInterestsChange(selectedInterests.filter((i) => i !== interestName));
    } else {
      if (maxSelectedCount && selectedInterests.length >= maxSelectedCount) return;
      onInterestsChange([...selectedInterests, interestName]);
    }
  };

  const gridLayout = layoutType === "grid";

  const selectedStyle = (name:string) => selectedInterests.includes(name) ? "!tw-border-[#1E28FF] tw-border-1" : "!tw-border-[#EDEDEB]";
  const renderButton = (interest: typeof allInterests[0], index: number) => (
    <Button
      key={interest.name}
      variant="outline"
      className={`tw-text-lg tw-flex tw-flex-col tw-w-[109px] sm:tw-w-[119px] tw-items-center tw-justify-center tw-p-2 sm:tw-p-4 tw-h-[66px] sm:tw-h-20 tw-rounded-lg ${selectedStyle(interest.name)}`}
      onClick={() => toggleInterest(interest.name)}
    >
      <span className="tw-text-xl sm:tw-text-2xl tw-mb-1">{interest.emoji}</span>
      <span className="tw-text-xs sm:tw-text-sm tw-leading-tight">{interest.name}</span>
    </Button>
  );

  return (
    <div className={gridLayout ? "tw-mx-auto tw-max-w-[524px]" : "tw-overflow-x-auto"}>
      {/* Small screen layout */}
      <div className="tw-grid tw-grid-cols-3 tw-gap-2 sm:tw-hidden">
        {allInterests.slice(0, 9).map(renderButton)}
      </div>
      <div className="tw-flex tw-justify-center tw-mt-2 sm:tw-hidden">
        {renderButton(allInterests[9], 9)}
      </div>

      {/* Large screen layout */}
      <div className="tw-hidden sm:tw-block">
        <div className="tw-grid tw-grid-cols-4 tw-gap-4">
          {allInterests.slice(0, 8).map(renderButton)}
        </div>
        <div className="tw-flex tw-justify-center tw-gap-4 tw-mt-4">
          {allInterests.slice(8).map(renderButton)}
        </div>
      </div>
    </div>
  );
};

