import React from "react";
import { observer } from "mobx-react-lite";
import { PictureUpload } from "../profile-picture";
import { Input } from "../../shadcn-components/input";
import { Textarea } from "../../shadcn-components/textarea";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn-components/form";
import { Button } from "../../shadcn-components/button";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { communityStore } from "../../stores/community-store";

// Assume we have an AvatarPlaceholder component
const formSchema = z.object({
  communityName: z.string().min(2, {
    message: "Community name must be at least 2 characters.",
  }),
  username: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  communityDescription: z.string().max(300, {
    message: "Description must not exceed 300 characters.",
  }),
});

const CreateCommunityForm: React.FC = observer(() => {
  const { createCommunity } = communityStore;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      communityName: "",
      username: "",
      communityDescription: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    createCommunity(values);
  }

  return (
    <div className="tw-max-w-[400px] tw-mx-auto tw-mt-8">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-6">
          <div className="tw-flex tw-flex-col tw-items-center tw-space-y-2 tw-mb-6">
            <PictureUpload onImageUpload={() => {}} currentImage={null} />
            <span className="tw-text-sm tw-text-gray-500">
              Add Community Icon
            </span>
          </div>

          <FormField
            control={form.control}
            name="communityName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="tw-text-left tw-block tw-text-grey">
                  Community Name
                </FormLabel>
                <FormControl>
                  <Input placeholder="Enter Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="tw-text-left tw-block tw-text-grey">
                  Username
                </FormLabel>
                <FormControl>
                  <Input placeholder="username" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="communityDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="tw-text-left tw-block tw-text-grey">
                  Community Description
                </FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="sassy bio 123"
                    className="tw-resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button className="tw-w-full tw-mt-4">Submit</Button>
        </form>
      </Form>
    </div>
  );
});

export default CreateCommunityForm;
