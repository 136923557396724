"use client";

import * as React from "react";
import { CalendarIcon, SquarePen } from "lucide-react";
import {
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
} from "date-fns";
import { toDate, formatInTimeZone } from "date-fns-tz";
import { DateRange } from "react-day-picker";
import { cva, VariantProps } from "class-variance-authority";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { cn } from "../lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import { Calendar } from "./calendar";
import { Input } from "./input";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const multiSelectVariants = cva(
  "tw-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-medium tw-text-foreground tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50",
  {
    variants: {
      variant: {
        default:
          "tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary/90",
        destructive:
          "tw-bg-destructive tw-text-destructive-foreground hover:tw-bg-destructive/90",
        outline:
          "tw-border tw-border-input tw-bg-background hover:tw-bg-accent hover:tw-text-accent-foreground",
        secondary:
          "tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80",
        ghost:
          "hover:tw-bg-accent hover:tw-text-accent-foreground tw-text-background",
        link: "tw-text-primary tw-underline-offset-4 hover:tw-underline tw-text-background",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);
interface CalendarDatePickerProps
  extends React.HTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof multiSelectVariants> {
  id?: string;
  className?: string;
  date: DateRange | undefined;
  closeOnSelect?: boolean;
  numberOfMonths?: 1 | 2;
  yearsRange?: number;
  onDateSelect: (range: DateRange | undefined) => void;
  disabled?: boolean;
}

export const CalendarDatePickerWithEdit = React.forwardRef<
  HTMLButtonElement,
  CalendarDatePickerProps
>(
  (
    {
      id = "calendar-date-picker",
      className,
      date,
      closeOnSelect = true,
      numberOfMonths = 1,
      yearsRange = 10,
      onDateSelect,
      variant = "link",
      disabled = false,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [month, setMonth] = React.useState<Date | undefined>(date?.from);
    const [year, setYear] = React.useState<number | undefined>(
      date?.from?.getFullYear()
    );

    const handleToggle = () => {
      if (!disabled) {
        setIsOpen(!isOpen);
      }
    };

    const handleDateSelect = (selectedDate: Date | undefined) => {
      onDateSelect(
        selectedDate ? { from: selectedDate, to: selectedDate } : undefined
      );
      if (closeOnSelect) {
        setIsOpen(false);
      }
    };

    const formatDate = (date: Date | undefined) => {
      if (!date) return "";
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

    const years = React.useMemo(() => {
      const currentYear = new Date().getFullYear();
      return Array.from(
        { length: yearsRange },
        (_, i) => currentYear - yearsRange / 2 + i
      );
    }, [yearsRange]);

    const handleMonthChange = (newMonth: string) => {
      const newDate = new Date(year || 0, months.indexOf(newMonth), 1);
      setMonth(newDate);
    };

    const handleYearChange = (newYear: string) => {
      const yearNum = parseInt(newYear, 10);
      setYear(yearNum);
      setMonth(new Date(yearNum, month?.getMonth() || 0, 1));
    };

    return (
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <div className="tw-relative">
            <Input
              type="text"
              value={formatDate(date?.from)}
              className="tw-pr-10 tw-rounded-full tw-border-gray-300"
              disabled={true}
            />
            <Button
              ref={ref}
              {...props}
              className={cn(
                "tw-absolute tw-right-[1px] tw-top-[1px] !tw-text-black hover:tw-text-black/80",
                multiSelectVariants({ variant: "link" })
              )}
              variant="link"
              size="icon"
              onClick={handleToggle}
              disabled={disabled}
            >
              <SquarePen className="tw-h-3 tw-w-3" />
            </Button>
          </div>
        </PopoverTrigger>
        {isOpen && (
          <PopoverContent className="tw-w-auto tw-p-0" align="start">
            <div className="tw-flex tw-justify-between tw-p-2">
              <Select
                onValueChange={handleMonthChange}
                value={months[month?.getMonth() || 0]}
              >
                <SelectTrigger className="tw-w-[120px]">
                  <SelectValue placeholder="Month" />
                </SelectTrigger>
                <SelectContent>
                  {months.map((m) => (
                    <SelectItem key={m} value={m}>
                      {m}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Select onValueChange={handleYearChange} value={year?.toString()}>
                <SelectTrigger className="tw-w-[100px]">
                  <SelectValue placeholder="Year" />
                </SelectTrigger>
                <SelectContent>
                  {years.map((y) => (
                    <SelectItem key={y} value={y.toString()}>
                      {y}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Calendar
              mode="single"
              selected={date?.from}
              onSelect={handleDateSelect}
              month={month}
              onMonthChange={setMonth}
              initialFocus
            />
          </PopoverContent>
        )}
      </Popover>
    );
  }
);

CalendarDatePickerWithEdit.displayName = "CalendarDatePickerWithEdit";
