type InterestName =
  | "Book clubs"
  | "Fitness"
  | "Startups"
  | "Food"
  | "Sports"
  | "Arts"
  | "Adventure"
  | "Travel"
  | "Board Games"
  | "Dancing";

export const interestNames: InterestName[] = [
  "Book clubs",
  "Fitness",
  "Startups",
  "Food",
  "Sports",
  "Arts",
  "Adventure",
  "Travel",
  "Board Games",
  "Dancing",
];

export interface Interest {
  name: InterestName;
  emoji: string;
}

export const allInterests: Interest[] = [
  { name: "Book clubs", emoji: "📖" },
  { name: "Fitness", emoji: "🏃‍♀️" },
  { name: "Startups", emoji: "🦄" },
  { name: "Food", emoji: "🍕" },
  { name: "Sports", emoji: "⚽" },
  { name: "Arts", emoji: "🎨" },
  { name: "Adventure", emoji: "🧗‍♂️" },
  { name: "Travel", emoji: "✈️" },
  { name: "Board Games", emoji: "♟️" },
  { name: "Dancing", emoji: "💃" },
];
