import { FC } from "react";
import { Button } from "src/shadcn-components/button";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import signUpStore from "../stores/signup.store";
import { Logo } from "./logo";

export const LoginSignUp: FC = () => {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      if (codeResponse) {
        const accountExists = false;
        console.log(codeResponse);
        if (accountExists) {
          // redirect to home page
        } else {
          signUpStore.setAccessToken(codeResponse.access_token);
          navigate("/register");
        }
      } else {
        console.error("Received offline response");
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-min-h-screen tw-p-4">
      <Logo className=" tw-mb-32" />
      <div className="tw-flex tw-flex-col tw-items-center tw-mt-38 tw-mb-auto">
        <h3 className="tw-text-2xl tw-font-semibold tw-mb-8 tw-text-center">
          Welcome!
        </h3>
        <Button
          variant="secondary"
          className="tw-flex tw-items-center tw-justify-center tw-w-64 tw-py-2 tw-px-4  tw-rounded-md tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50"
          onClick={() => login()}
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google logo"
            className="tw-w-5 tw-h-5 tw-mr-2"
          />
          Continue with Google
        </Button>
      </div>
      <div className="tw-mt-137 tw-mb-20 tw-text-center">
        {" "}
        {/* 548px (137 * 4px) below button, 80px (20 * 4px) above bottom */}
        <p className="tw-text-sm tw-text-gray-600">
          By signing up, you agree to our Terms.
          <br />
          See how we use your data in Privacy Policy.
        </p>
      </div>
    </div>
  );
};
