import React, { useEffect, useRef, useState } from "react";
import { LogOut, ArrowLeft, SquarePen } from "lucide-react";
import { Button } from "../shadcn-components/button";
import { Input } from "../shadcn-components/input";
import { observer } from "mobx-react-lite";
import { DateRange } from "react-day-picker";
import { CalendarDatePickerWithEdit } from "../shadcn-components/datepicker-with-edit";
import { DeleteProfileDialog } from "./delete-profile-dialog";
import profileSettingsStore from "../stores/profile-settings-store";
import EditableInput from "./editable-input";

interface ProfileSettingsProps {
  onClose: () => void;
}

const ProfileSettings: React.FC<ProfileSettingsProps> = observer(() => {
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [name, setName] = useState("Jane");
  const [phone, setPhone] = useState("+91 8790-23474");
  const nameInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const [dateOfBirth, setDateOfBirth] = useState<DateRange | undefined>({
    from: new Date("2024-07-24"),
    to: new Date("2024-07-24"),
  });

  const handleBack = () => {
    profileSettingsStore.closeSettings();
  };

  const handleDateSelect = (range: DateRange | undefined) => {
    setDateOfBirth(range);
  };

  const handlePhoneEdit = () => {
    setIsEditingPhone(true);
    setTimeout(() => phoneInputRef.current?.focus(), 0);
  };

  const handleNameSave = () => {
    // Add your logic to save the name here
    console.log("Saving name:", name);
  };

  const handlePhoneSave = () => {
    setIsEditingPhone(false);
    // Add your logic to save the phone number here
    console.log("Saving phone:", phone);
  };

  const handleDelete = () => {
    console.log("Delete account");
    // Add your logic to delete the account here
  };
  return (
    <div className="tw-w-full tw-max-w-md tw-mx-auto  tw-rounded-lg tw-p-6">
      <div className="tw-flex tw-items-center tw-mb-6">
        <Button
          variant="secondary"
          size="icon"
          onClick={handleBack}
          className="tw-mr-2 !tw-rounded-3xl"
        >
          <ArrowLeft className="tw-h-6 tw-w-6" />
        </Button>
        <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700">
          Profile Settings
        </h2>
      </div>

      <div className="tw-space-y-4">
        <EditableInput label="Name" value={name} onSave={handleNameSave} />

        <EditableInput label="Phone" value={phone} onSave={handlePhoneSave} />

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-600 tw-mb-1">
            Date of Birth
          </label>

          <CalendarDatePickerWithEdit
            date={dateOfBirth}
            onDateSelect={handleDateSelect}
            closeOnSelect={true}
            numberOfMonths={1}
          />
        </div>
      </div>

      <div className="tw-mt-8 tw-space-y-4">
        <Button
          variant="secondary"
          className="tw-w-full tw-rounded-full tw-bg-gray-100 tw-text-gray-700 hover:tw-bg-gray-200"
        >
          <LogOut className="tw-h-4 tw-w-4 tw-mr-2" />
          Log out
        </Button>
        <DeleteProfileDialog onDelete={handleDelete} />
      </div>
    </div>
  );
});

export default ProfileSettings;
