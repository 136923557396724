import React from "react";
import { SquarePen } from "lucide-react";
import { Textarea, TextareaProps } from "./textarea";
import { Button } from "./button";

interface CustomTextareaWithIconProps extends Omit<TextareaProps, "onChange"> {
  onIconClick?: () => void;
  onChange?: (value: string) => void;
}

export const CustomTextareaWithIcon: React.FC<CustomTextareaWithIconProps> = ({
  onIconClick,
  onChange,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <div className="tw-relative">
      <Textarea onChange={handleChange} {...props} />
      <Button 
        className="tw-absolute tw-right-[1px] tw-top-[1px] " 
        variant="link"
        size="icon"
        onClick={onIconClick}
      >
        <SquarePen className="tw-h-3 tw-w-3 tw-text-grey" />
      </Button>
    </div>
  );
};
