import { action, makeAutoObservable, observable } from "mobx";

class ProfileSettingsStore {
  @observable isSettingsOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  toggleSettings() {
    this.isSettingsOpen = !this.isSettingsOpen;
  }

  @action
  openSettings() {
    this.isSettingsOpen = true;
  }

  @action
  closeSettings() {
    this.isSettingsOpen = false;
  }
}

const profileSettingsStore = new ProfileSettingsStore();
export default profileSettingsStore;
