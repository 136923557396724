import { ChevronDown, SquarePen } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { Button } from "./button";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";

interface CustomDropdownWithIconProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  onSelect: () => void;
}

export const CustomDropdownWithIcon: React.FC<CustomDropdownWithIconProps> = ({
  options,
  value,
  onChange,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option: string) => {
    onChange(option);
    onSelect();
    setIsOpen(false);
  };

  return (
    <div className="tw-relative">
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline" 
          className="tw-w-full tw-pr-10 tw-justify-between !tw-border-custom-border"
          disabled={!isOpen}
        >
          {value}
        </Button>
      </DropdownMenuTrigger>
      <Button 
        className="tw-absolute tw-right-1 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-p-0" 
        variant="link"
        size="icon"
        onClick={() => setIsOpen(true)}
      >
        <SquarePen className="tw-h-3 tw-w-3 tw-text-grey" />
      </Button>
      <DropdownMenuContent className="tw-w-[var(--radix-dropdown-menu-trigger-width)] tw-max-h-[300px] tw-overflow-y-auto">
        {options.map((option) => (
          <DropdownMenuItem key={option} onSelect={() => handleSelect(option)}>
            {option}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
  );
};
