import { FC } from "react";
import { InterestButtons } from "./interest-buttons";

interface InterestsProps {
  selectedInterests: string[];
  onInterestsChange: (interests: string[]) => void;
  layoutType?: "grid" | "horizontal"; // New prop to control layout type
}

export const SignUpInterests: FC<InterestsProps> = ({
  selectedInterests,
  onInterestsChange,
  layoutType = "grid",
}) => {
  return (
    <div className="tw-text-center">
      <p className="tw-text-gray-500 tw-mb-6">{`Could be anything, think about what you really likeeee :)`}</p>
      <InterestButtons
        selectedInterests={selectedInterests}
        onInterestsChange={onInterestsChange}
        layoutType={layoutType}
      />
    </div>
  );
};
