import { FC } from "react";
import { cn } from "../lib/utils";
import { Button } from "../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { Avatar } from "../shadcn-components/avatar";
import { useLocation, useNavigate } from "react-router-dom";
import bhagClubProfile from "../static/bhag-profile-pic.png";
import { Logo } from "./logo";

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  activeSection: "events" | "communities";
  onSectionChange: (section: "events" | "communities") => void;
  onProfileClick: () => void;
}

export const Sidebar: FC<SidebarProps> = observer(
  ({ onProfileClick, onSectionChange, activeSection }) => {
    const menuItems = [
      { id: "explore", label: "Events", icon: EventsIcon },
      { id: "calendar", label: "Calendar", icon: CalendarIcon },
      { id: "c", label: "Communities", icon: CommunitiesIcon },
      { id: "broadcast", label: "Broadcast", icon: BroadcastIcon },
    ];

    const navigate = useNavigate();
    const location = useLocation();

    const onStartCommunityClick = () => {
      navigate("/c/new");
    };

    const handleItemClick = (itemId: string) => {
      if (itemId === "explore" || itemId === "c") {
        navigate(`/${itemId}`);
      }
      // For other items, you might want to handle them differently or ignore them
    };

    const isActive = (path: string) => location.pathname === `/${path}`;

    return (
      <div className="tw-fixed tw-left-0 tw-top-0 tw-bottom-0 tw-w-[261px] tw-bg-white tw-border-r tw-border-gray-200 tw-flex tw-flex-col">
        <div className="tw-p-4">
          <Logo className="tw-mb-6" />
        </div>
        <nav className="tw-flex-1 tw-space-y-1 tw-px-4">
          {menuItems.map((item) => (
            <Button
              key={item.id}
              variant="ghost"
              className={cn(
                "tw-w-full tw-justify-start tw-px-2 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700",
                isActive(item.id) && "tw-text-blue-600 tw-bg-blue-50"
              )}
              onClick={() => handleItemClick(item.id)}
            >
              <item.icon
                className={cn(
                  "tw-mr-3 tw-h-5 tw-w-5",
                  isActive(item.id) ? "tw-text-blue-600" : "tw-text-gray-400"
                )}
              />
              {item.label}
            </Button>
          ))}
        </nav>
        <div className="tw-p-4 tw-space-y-4">
          <Button
            variant="default"
            className="tw-w-full tw-justify-start tw-px-4 tw-font-normal !tw-rounded-full tw-bg-primary tw-text-white hover:tw-bg-blue-700"
            onClick={onStartCommunityClick}
          >
            {/* {overlappingCircles} */}
            <span className="tw-ml-2">Start a community</span>
          </Button>
          <div className="tw-h-px tw-bg-gray-200 tw-my-4"></div>
          <Button
            variant="ghost"
            className="tw-w-1/2 tw-justify-start tw-px-2 tw-py-0 tw-text-gray-700"
            onClick={onProfileClick}
          >
            <Avatar className="tw-w-8 tw-h-8 tw-mr-3">
              <img src="https://github.com/shadcn.png" alt={'alt name'} />
            </Avatar>
            <span className="tw-font-semibold">Vikram</span>
          </Button>
        </div>
      </div>
    );
  }
);

const EventsIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    className="lucide lucide-ticket"
  >
    <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
    <path d="M13 5v2" />
    <path d="M13 17v2" />
    <path d="M13 11v2" />
  </svg>
);

const CalendarIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <rect width="24" height="24" fill="none" />
    <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
  </svg>
);

const CommunitiesIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <rect width="24" height="24" fill="none" />
    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
  </svg>
);

const BroadcastIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <rect width="24" height="24" fill="none" />
    <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-10 9H4c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h7v7zm10 0h-7V5h7c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1z" />
  </svg>
);
