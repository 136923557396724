import { Trash2 } from "lucide-react";
import { Button } from "../shadcn-components/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../shadcn-components/dialog";
import { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import whatsappIcon from "../static/whatsapp.svg";
import { Textarea } from "../shadcn-components/textarea";
interface CustomDeleteDialogProps {
  onDelete: () => void;
}
export const DeleteProfileDialog: FC<CustomDeleteDialogProps> = observer(
  ({ onDelete }) => {
    const [isFirstDialogOpen, setIsFirstDialogOpen] = useState(false);
    const [isSecondDialogOpen, setIsSecondDialogOpen] = useState(false);
    const [feedback, setFeedback] = useState("");

    const handleFirstDelete = () => {
      setIsFirstDialogOpen(false);
      setIsSecondDialogOpen(true);
    };

    const handleFinalDelete = () => {
      onDelete();
      setIsSecondDialogOpen(false);
    };

    return (
      <>
        <Dialog open={isFirstDialogOpen} onOpenChange={setIsFirstDialogOpen}>
          <DialogTrigger asChild>
            <Button
              variant="ghost"
              className="tw-w-full tw-rounded-full tw-text-red-500 hover:tw-bg-red-50 hover:tw-text-red-600"
            >
              <Trash2 className="tw-h-4 tw-w-4 tw-mr-2" />
              Delete
            </Button>
          </DialogTrigger>
          <DialogContent className="tw-bg-white tw-rounded-lg tw-p-6 tw-max-w-sm tw-mx-auto">
            <div className="tw-text-center">
              <h2 className="tw-text-xl tw-font-semibold tw-mb-16">
                Are you sure you want to delete?
              </h2>
              <p className="tw-text-gray-600 tw-mb-6">
                Please don't leave us, let's talk about it!
              </p>
              <Button className="!tw-bg-green-500 tw-text-white tw-rounded-full tw-py-2 tw-px-4 tw-mb-16 tw-w-full">
                <img
                  src={whatsappIcon}
                  className="tw-w-5 tw-h-5 tw-mr-2"
                  alt="WhatsApp"
                />
                Text us on WhatsApp
              </Button>

              <Button
                variant="secondary"
                className="tw-bg-gray-200 tw-text-gray-800 tw-rounded-full tw-py-2 tw-px-4 tw-w-full"
                onClick={handleFirstDelete}
              >
                Delete
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog open={isSecondDialogOpen} onOpenChange={setIsSecondDialogOpen}>
          <DialogContent className="tw-bg-white tw-rounded-lg tw-p-6 tw-max-w-sm tw-mx-auto">
            <div className="tw-text-center">
              <h2 className="tw-text-xl tw-font-semibold tw-mb-2">
                Are you sure you want to delete?
              </h2>
              <p className="tw-text-gray-600 tw-mb-6">
                We’ll really miss you, is there something better that we could
                have done?
              </p>
              <Textarea
                placeholder="Type your message here"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="tw-mb-4"
              />
              <Button
                className="tw-bg-primary tw-text-white tw-rounded-full tw-py-2 tw-px-4 tw-mb-2 tw-w-full"
                onClick={handleFinalDelete}
              >
                Delete
              </Button>
              <Button
                variant="secondary"
                className="tw-bg-gray-200 tw-text-gray-800 tw-rounded-full tw-py-2 tw-px-4 tw-w-full"
                onClick={() => setIsSecondDialogOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
);
