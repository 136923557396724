import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "../../shadcn-components/badge";
import { Button } from "../../shadcn-components/button";
import { MapPin, Share2, ShoppingBag } from "lucide-react";

import bhagClubProfile from "../../static/bhag-profile-pic.png";
import bhagClubBanner from "../../static/bhag-banner.jpg";
import { Avatar, AvatarImage } from "@radix-ui/react-avatar";
import { VerifiedCheckMark } from "../verified-checkmark";
import { EventCard } from "./event-card";
import instagramIcon from "../../static/instagram.svg";
import twitterIcon from "../../static/twitter.svg";
import youtubeIcon from "../../static/youtube.svg";
import { communityStore } from "../../stores/community-store";
import FollowersListDialog from "./community-followers-dialog";
import CommunityFollowers from "./community-followers-dialog";

type CommunityProfileProps = {
  id?: string;
  name?: string;
  handle?: string;
  description?: string;
  location?: string;
  category?: string;
  followers?: string;
  bannerUrl?: string;
  avatarUrl?: string;
};

const communityData = {
  id: 155,
  name: "Bhag Club",
  handle: "bhagclub",
  description:
    "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
  location: "Delhi NCR",
  category: "Fitness",
  followers: "2Mn",
  bannerUrl: bhagClubBanner,
  avatarUrl: bhagClubProfile,
};
const events = [
  {
    imageUrl: bhagClubBanner,
    title: "Delhi Week 11",
    time: "7:00 AM",
    date: "8th October, 2024",
    slotsLeft: 10,
  },
  {
    imageUrl: bhagClubBanner,
    title: "Delhi Week 12",
    time: "7:00 AM",
    date: "15th October, 2024",
    slotsLeft: 50,
  },
  {
    imageUrl: bhagClubBanner,
    title: "Delhi Week 13",
    time: "7:00 AM",
    date: "22th October, 2024",
    slotsLeft: 100,
  },
  // ... more events
];
export const CommunityView: FC<CommunityProfileProps> = observer(
  ({
    id = communityData.id,
    name = communityData.name,
    handle = communityData.handle,
    description = communityData.description,
    location = communityData.location,
    category = communityData.category,
    followers = communityData.followers,
    //   subscribers=communityData.subscribers,
    bannerUrl = communityData.bannerUrl,
    avatarUrl = communityData.avatarUrl,
  }) => {
    const { communityHandle } = useParams<{ communityHandle: string }>();
    const { setCurrentCommunityViewName } = communityStore;
    const [isFollowersDialogOpen, setIsFollowersDialogOpen] = useState(false);

    useEffect(() => {
      // Fetch community data here
      // set current community
      communityStore.setCurrentCommunityViewName("Bhag Club");
    }, [setCurrentCommunityViewName]);

    const navigate = useNavigate();

    const goToManagePage = () => {
      navigate(`/${handle}/manage`);
    };

    return (
      <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-bg-white tw-rounded-lg tw-overflow-hidden">
        <div className="tw-relative">
          <Avatar>
            <AvatarImage
              className="tw-w-full tw-h-48 tw-object-cover"
              src={bannerUrl}
              alt="Community banner"
            />
          </Avatar>
          <div className="tw-absolute tw-bottom-0 tw-left-4 tw-transform tw-translate-y-1/2">
            <img
              src={avatarUrl}
              alt="Community avatar"
              className="tw-w-24 tw-h-24 tw-rounded-lg"
            />
          </div>
        </div>
        <div className="tw-p-4 tw-pt-16">
          <div className="tw-flex tw-justify-between tw-items-start">
            <div className="tw-flex-grow">
              <div className="tw-flex tw-justify-between tw-items-center">
                <h1 className="tw-text-2xl tw-font-bold tw-flex tw-items-center">
                  {name}
                  <VerifiedCheckMark />
                </h1>
                {/*  TODO: different buttons based on user is admin or not */}
                <div className="tw-space-x-2">
                  <Button variant="secondary" onClick={goToManagePage}>
                    Manage Community
                  </Button>
                  <Button variant="default">Create an event</Button>
                </div>
              </div>
              <p className="tw-text-gray-600 tw-text-left">@{handle}</p>
            </div>
          </div>
          <p className="tw-mt-4 tw-text-left">{description}</p>
          <div className="tw-flex tw-items-center tw-mt-4 tw-space-x-4">
            <span className="tw-flex tw-items-center tw-text-gray-600">
              <MapPin className="tw-w-4 tw-h-4 tw-mr-1" />
              {location}
            </span>
            <span className="tw-flex tw-items-center tw-text-gray-600">
              <Badge variant="outline">{category}</Badge>
            </span>
          </div>

          <div className="tw-flex tw-items-center tw-mt-4 tw-space-x-4">
            <div className="tw-flex tw-items-center tw-space-x-2">
              <img
                src={instagramIcon}
                alt="Instagram"
                className="tw-w-5 tw-h-5"
              />
              <span>Instagram</span>
            </div>
            <div className="tw-flex tw-items-center tw-space-x-2">
              <img src={youtubeIcon} alt="Youtube" className="tw-w-5 tw-h-5" />
              <span>Youtube</span>
            </div>
            <div className="tw-flex tw-items-center tw-space-x-2">
              <img src={twitterIcon} alt="Twitter" className="tw-w-5 tw-h-5" />
              <span>Twitter</span>
            </div>
            <div className="tw-flex tw-items-center tw-space-x-2">
              <ShoppingBag className="tw-w-5 tw-h-5 tw-text-gray-600" />
              <span>Shop</span>
            </div>
            <div className="tw-flex tw-items-center tw-space-x-2">
              <Share2 className="tw-w-5 tw-h-5 tw-text-gray-600" />
              <span>Share Community</span>
            </div>
          </div>
          <div className="tw-flex tw-mt-4 tw-space-x-4">
            <Button onClick={() => setIsFollowersDialogOpen(true)}>
              {`${followers} Followers`}{" "}
            </Button>
            <CommunityFollowers
              isOpen={isFollowersDialogOpen}
              onClose={() => setIsFollowersDialogOpen(false)}
            />
          </div>
        </div>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6 tw-max-w-4xl tw-mx-auto tw-px-4">
          {events.map((event, index) => (
            <EventCard key={index} {...event} onRSVP={() => {}} />
          ))}
        </div>
      </div>
    );
  }
);
