import { useState } from "react";
import { Button } from "../../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { communityStore } from "../../stores/community-store";
import EditableInput from "../editable-input";
import { CustomTextareaWithIcon } from "../../shadcn-components/custom-textarea-with-icon";
import CommunityImageBanner from "./community-banner-avatar-edit";
import bhagProfilePic from "../../static/bhag-profile-pic.png";
import bhagBanner from "../../static/bhag-banner.jpg";
import { useNavigate } from "react-router-dom";

const ManageCommunityForm = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEditingBio, setIsEditingBio] = useState(false);

  // ... (rest of the component logic remains the same)
  const navigate = useNavigate();
  const handleManageAdmins = () => {
    navigate(
      `/${communityStore.communityData.communityUsername}/manage/admins`
    );
  };
  return (
    <div className="tw-max-w-3xl tw-mx-auto tw-p-6">
      <CommunityImageBanner
        initialBanner={bhagBanner}
        initialAvatar={bhagProfilePic}
      />

      <div className="tw-mt-12 tw-w-full tw-max-w-[343px] md:tw-max-w-[400px] tw-mx-auto tw-space-y-6">
        <EditableInput
          label="Community Name"
          value={communityStore.communityData.communityName}
          // onSave={(value) => handleSave('communityName', value)}
          onSave={() => {}}
        />

        <EditableInput
          label="Community Username"
          value={communityStore.communityData.communityUsername}
          // onSave={(value) => handleSave('communityUsername', value)}
          onSave={() => {}}
        />

        <div className="tw-relative">
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
            <label className="tw-text-sm tw-font-medium tw-text-grey">
              Bio
            </label>
          </div>
          <CustomTextareaWithIcon
            value={communityStore.communityData.bio}
            onChange={() => {}}
            disabled={!isEditingBio}
            className="tw-min-h-[100px] tw-w-full tw-p-2 tw-rounded-md"
            onIconClick={() => setIsEditingBio(!isEditingBio)}
          />
        </div>

        <EditableInput
          label="Location"
          value={communityStore.communityData.location}
          // onSave={(value) => handleSave('location', value)}
          onSave={() => {}}
        />

        <EditableInput
          label="Category"
          value={communityStore.communityData.category}
          // onSave={(value) => handleSave('category', value)}
          onSave={() => {}}
        />

        <EditableInput
          label="Twitter"
          value={communityStore.communityData.twitter}
          // onSave={(value) => handleSave('twitter', value)}
          onSave={() => {}}
        />

        <EditableInput
          label="Instagram"
          value={communityStore.communityData.instagram}
          // onSave={(value) => handleSave('instagram', value)}
          onSave={() => {}}
        />

        <EditableInput
          label="YouTube"
          value={communityStore.communityData.youtube}
          // onSave={(value) => handleSave('youtube', value)}
          onSave={() => {}}
        />

        <Button
          variant="outline"
          className="tw-mt-6 tw-w-full"
          onClick={handleManageAdmins}
        >
          Manage Admins
        </Button>
      </div>
    </div>
  );
});

export default ManageCommunityForm;
