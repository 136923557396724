import { observer } from "mobx-react-lite";
import { FC } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/card";
import { Badge } from "../../shadcn-components/badge";
import { Community } from "./communities-home";
import verifiedBadge from '../../static/blue-check.svg';
import { useNavigate } from "react-router-dom";

export const CommunityCard: FC<{ community: Community }> = observer(
  ({ community }) => {
    const navigate = useNavigate();
    const onCardClick = () => {
      navigate(`/bhag`);
    }
    return (
      <Card className="tw-overflow-hidden tw-border-custom-border hover:tw-cursor-pointer" onClick={onCardClick}>
      <div className="tw-relative">
        <img
          src={community.bannerImage}
          alt={`${community.name} banner`}
          className="tw-w-full tw-h-32 tw-object-cover"
        />
        <img
          src={community.profileImage}
          alt={community.name}
          className="tw-absolute tw-bottom-0 tw-left-4 tw-transform tw-translate-y-1/2 tw-w-18 tw-h-18 tw-object-cover"
        />
      </div>
      <CardHeader className="tw-pt-12 tw-pb-2 tw-px-4">
        <div className="tw-flex tw-items-center tw-space-x-2">
          <div className="tw-flex tw-items-center">
            <CardTitle className="tw-text-base tw-m-0 tw-leading-6">{community.name}</CardTitle>
            {community.verified && (
              <Badge variant="secondary" className="!tw-p-0 !tw-m-0 tw-ml-1">
                <img
                  src={verifiedBadge}
                  alt="Verified"
                  className="tw-w-4 tw-h-4"
                />
              </Badge>
            )}
          </div>
          <CardDescription className="tw-m-0 tw-text-sm tw-leading-6">
            {community.handle}
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="tw-pb-4 tw-px-4 tw-text-sm">
        <p className="tw-text-left">{community.description}</p>
      </CardContent>
      <CardFooter className="tw-justify-between tw-pb-4">
        <p className="tw-text-sm tw-text-gray-500">
          {community.followers} Followers
        </p>
      </CardFooter>
    </Card>
    );
  }
);
