import { Search } from "lucide-react";
import { Input } from "../../shadcn-components/input";
export const CommunitiesHeaderSearch = () => {
  return (
    <section className="tw-bg-primary tw-rounded-lg tw-mx-0 tw-p-12 tw-text-white tw-text-center tw-h-[393px] tw-flex tw-flex-col tw-justify-center tw-items-center">
      <div className="tw-max-w-2xl tw-w-full">
        <h1 className="tw-text-4xl tw-font-bold tw-mb-4">
          Find your tribe on JackPass
        </h1>
        <p className="tw-text-xl tw-mb-8">
          From running to pottery to reading, there's a place for you.
        </p>
        <div className="tw-relative tw-w-full tw-max-w-xl tw-mx-auto">
          <Input
            type="text"
            placeholder="Search hobby or community name"
            className="tw-pl-6 tw-pr-12 tw-py-3 tw-w-full tw-bg-primary tw-text-white tw-placeholder-white tw-border-2 tw-border-white tw-rounded-3xl focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white tw-text-lg"
          />
          <Search
            className="tw-absolute tw-right-4 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-text-white"
            size={24}
          />
        </div>
      </div>
    </section>
  );
};
