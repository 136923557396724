import { observer } from "mobx-react-lite";
import { Sidebar } from "./sidenav";
import { FC, useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../shadcn-components/card";
import { Button } from "../shadcn-components/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn-components/select";
import { eventStore } from "../stores/event-store";

import { ScrollArea } from "../shadcn-components/scroll-area";
import { InterestButtons } from "./interest-buttons";
import { CommunitiesHome } from "./communities/communities-home";

import bhagClubProfile from "../static/bhag-profile-pic.png";
import bhagClubBanner from "../static/bhag-banner.jpg";
interface HubProps {
  view: "events" | "communities";
}
export const Hub: FC<HubProps> = observer(({ view }) => {
  const [interestsFilter, setInterestsFilter] = useState<string[]>([]);

  return (
    <>
      {view === "events" && (
        <div className="tw-flex tw-items-center tw-space-x-4 tw-mb-6">
          <>
            <Button variant="default">Create event</Button>
            <div className="tw-flex tw-items-center">
              <span className="tw-mr-2">Popular in</span>
              <Select
                value={eventStore.location}
                onValueChange={(value) => eventStore.setLocation(value)}
              >
                <SelectTrigger className="tw-w-[180px]">
                  <SelectValue placeholder="Select location" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Delhi NCR">Delhi NCR</SelectItem>
                  <SelectItem value="Mumbai">Mumbai</SelectItem>
                  <SelectItem value="Bangalore">Bangalore</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </>
        </div>
      )}

      {view === "events" ? (
        <>
          <section className="tw-mb-8">
            <h2 className="tw-text-xl tw-font-semibold tw-mb-4">
              Check out trending categories
            </h2>
            <ScrollArea className="tw-h-24 tw-max-w-full tw-overflow-x-auto">
              <div className="tw-flex tw-flex-wrap tw-gap-2">
                <InterestButtons
                  selectedInterests={interestsFilter}
                  onInterestsChange={setInterestsFilter}
                  layoutType="horizontal"
                />
              </div>
            </ScrollArea>
          </section>

          <section>
            <h2 className="tw-text-xl tw-font-semibold tw-mb-4">
              Events in {eventStore.location}
            </h2>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
              {eventStore.events.map((event) => (
                <Card key={event.id}>
                  <CardHeader>
                    <CardTitle className="!tw-h-6">{event.title}</CardTitle>
                    <CardDescription>
                      {event.date} at {event.time}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p>{event.description}</p>
                  </CardContent>
                  <CardFooter>
                    <p>Organized by: {event.organizer}</p>
                  </CardFooter>
                </Card>
              ))}
            </div>
          </section>
        </>
      ) : (
          <CommunitiesHome communities={communitiesData} />
      )}
    </>
  );
});

const communitiesData = [
  {
    id: 1,
    name: "Bhag Club",
    handle: "@bhagclub",
    description:
      "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
    followers: "2Mn",
    profileImage: bhagClubProfile,
    bannerImage: bhagClubBanner,
    verified: true,
  },
  {
    id: 2,
    name: "Delhi Royal Enfield Riders",
    handle: "@delhiriders",
    description:
      "Delhi Royal Enfield Riders is India's most exciting riding club, with group rides every weekend across Delhi.",
    followers: "200k",
    profileImage: bhagClubProfile,
    bannerImage: bhagClubBanner,
    verified: true,
  },
  // Add more community data as needed
];
