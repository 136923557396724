import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { cn } from "../lib/utils";
import { cva, VariantProps } from "class-variance-authority";

// const avatarVariants = cva(
//   "tw-inline-flex tw-items-center tw-justify-center tw-overflow-hidden",
//   {
//     variants: {
//       size: {
//         default: "tw-h-10 tw-w-10",
//         lg: "tw-h-14 tw-w-14",
//         sm: "tw-h-8 tw-w-8",
//         custom: "tw-h-[var(--avatar-size)] tw-w-[var(--avatar-size)]",
//       },
//       shape: {
//         default: "tw-rounded-sm",
//         rounded: "tw-rounded-full",
//       },
//     },
//     defaultVariants: {
//       size: "default",
//       shape: "default",
//     },
//   }
// );
const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> & {
    size?: number;
    shape?: 'square' | 'rounded';
    position?: string;
  }
>(({ className, size = 40, shape = 'rounded', position, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      "tw-relative tw-flex tw-shrink-0 tw-overflow-hidden",
      shape === 'rounded' ? 'tw-rounded-full' : 'tw-rounded-none',
      position,
      className
    )}
    style={{ width: `${size}px`, height: `${size}px` }}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

// https://github.com/shadcn-ui/ui/issues/2663#issuecomment-2206836899
const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image> & {
    objectFit?: "cover" | "contain" | "fill";
  }
>(({ className, objectFit = "cover", ...props }, ref) => (
  <img
    ref={ref}
    className={cn(`object-${objectFit} w-full h-full`, className)}
    {...props}
    alt={props.alt}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-bg-muted",
      className
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
