import { makeAutoObservable } from "mobx";

class EventStore {
    location: string = 'Delhi NCR';
  events: Array<{
    id: number;
    title: string;
    date: string;
    time: string;
    description: string;
    organizer: string;
  }> = [
    {
      id: 1,
      title: 'Delhi Week 11 Run',
      date: '8th September, 2024',
      time: '7:00 AM',
      description: 'Bhag Club is India\'s spiciest run club, with group runs every weekend across Delhi NCR.',
      organizer: 'Bhag Club'
    },
    // Add more events here...
  ];

  constructor() {
    makeAutoObservable(this);
  }

  setLocation(location: string) {
    this.location = location;
  }
}

export const eventStore = new EventStore();