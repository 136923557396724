import { cn } from "../lib/utils";
import { Button } from "../shadcn-components/button";
import { observer } from "mobx-react-lite";

interface SignUpGenderSelectionProps {
  selectedGender: string;
  handleSelect: (gender: string) => void;
}

export const SignUpGenderSelection = observer(
  ({ selectedGender, handleSelect }: SignUpGenderSelectionProps) => {
    const styles = (gender: string) => {
      return cn(
        "tw-rounded-3xl tw-font-bold",
        selectedGender === gender
          ? "!tw-border-[#1E28FF] tw-border-1"
          : "!tw-border-[#EDEDEB]"
      );
    };
    return (
      <div className="tw-flex tw-justify-center">
        <div className="tw-w-[400px] tw-flex tw-flex-col tw-space-y-2">
          {" "}
          <Button
            variant={"outline"}
            onClick={() => handleSelect("woman")}
            className={styles("woman")}
          >
            Woman
          </Button>
          <Button
            variant={"outline"}
            onClick={() => handleSelect("man")}
            className={styles("man")}
          >
            Man
          </Button>
          <Button
            variant={"outline"}
            onClick={() => handleSelect("non-binary")}
            className={styles("non-binary")}
          >
            Non-binary
          </Button>
        </div>
      </div>
    );
  }
);
