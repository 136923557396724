import { cn } from "../lib/utils";
import { Button } from "../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { SignUpReason } from "../stores/signup.store";

interface WhatDoYouWantToDoProps {
  selectedOption: SignUpReason;
  handleSelect: (opt: SignUpReason) => void;
}

export const SignupReason = observer(
  ({ selectedOption, handleSelect }: WhatDoYouWantToDoProps) => {
    const styles = (opt: SignUpReason) => {
      return cn(
        "tw-rounded-3xl tw-font-bold",
        selectedOption === opt
          ? "!tw-border-[#1E28FF] tw-border-1"
          : "!tw-border-[#EDEDEB]"
      );
    };
    return (
      <div className="tw-flex tw-justify-center">
        <div className="tw-w-[400px] tw-flex tw-flex-col tw-space-y-2">
          {" "}
          <Button
            variant={"outline"}
            onClick={() => handleSelect(SignUpReason.CreateACommunity)}
            className={styles(SignUpReason.CreateACommunity)}
          >
            {SignUpReason.CreateACommunity}
          </Button>
          <Button
            variant={"outline"}
            onClick={() => handleSelect(SignUpReason.FindEvents)}
            className={styles(SignUpReason.FindEvents)}
          >
            {SignUpReason.FindEvents}
          </Button>
          <Button
            variant={"outline"}
            onClick={() => handleSelect(SignUpReason.NotSureYet)}
            className={styles(SignUpReason.NotSureYet)}
          >
            {" "}
            {SignUpReason.NotSureYet}
          </Button>
        </div>
      </div>
    );
  }
);
