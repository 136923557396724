import axios from "axios";
import { action, makeAutoObservable, observable } from "mobx";
import { z } from "zod";
import { getPhoneData } from "../shadcn-components/phone";

export enum SignUpReason {
    CreateACommunity = "Create a community",
    FindEvents = "Find events to attend",
    NotSureYet = "Not sure yet"
}
export const formSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters long."),
  phone: z
    .string({ required_error: "Phone is required" })
    .refine(
      (val) => {
        // Remove all non-digit characters except the leading '+'
        const cleanedNumber = val.replace(/(?!^\+)\D/g, '');
        
        // Check if it's a valid Indian phone number with country code
        return /^\+91\d{10}$/.test(cleanedNumber);
      },
      {
        message: "Invalid Indian phone number. Must be 10 digits long.",
      }
    ),
  location: z.string().optional(),
  profilePicture: z
    .instanceof(File)
    .refine((file) => file.size < 5000000, {
      message: "Your profile picture must be less than 5MB.",
    })
    .optional(),
  dateOfBirth: z
    .date({
      required_error: "A date of birth is required.",
    })
    .refine((date) => date <= new Date(), {
      message: "Date of birth cannot be in the future.",
    }),
  interests: z.string().array().min(1, "Please select at least 1 interest.").max(5, "You can select up to 5 interests."),
  gender: z.string(),
  signUpReason: z.enum([SignUpReason.CreateACommunity, SignUpReason.FindEvents, SignUpReason.NotSureYet]),
});


export type FormValues = z.infer<typeof formSchema>;

class SignUpStore {
  @observable selectedInterests: string[] = [];
  @observable isSubmitting = false;
  @observable submitError = "";
  @observable authenticatedToken = "";

  constructor() {
    makeAutoObservable(this);
  }

  @action
  toggleInterest = (interest: string) => {
    if (this.selectedInterests.includes(interest)) {
      this.selectedInterests = this.selectedInterests.filter(
        (i) => i !== interest
      );
    } else {
      this.selectedInterests.push(interest);
    }
  };

  @action
  setAccessToken = (token: string) => {
    this.authenticatedToken = token;
  };

  @action
  onPhoneNext = (phone: string) => {
    const phoneData = getPhoneData(phone);

    if (!phoneData.isValid) {
      // form.("phone", {
      // 	type: "manual",
      // 	message: "Invalid phone number",
      // });
      console.log("phone invalid error");
      return;
    }
    // toast.success("Phone number is valid");
  };

  @action
  handleSubmit = async (data: FormValues) => {
    this.isSubmitting = true;
    this.submitError = "";

    try {
      // Prepare the JSON payload
      const jsonPayload: Partial<{
        [K in keyof FormValues]: string | string[] | null;
      }> = {};

      (Object.keys(data) as Array<keyof FormValues>).forEach((key) => {
        const value = data[key];
        if (value != null && value !== '') {
          if (key === "dateOfBirth" && value instanceof Date) {
            jsonPayload[key] = value.toISOString();
          } else if (key === "profilePicture" && value instanceof File) {
            // For now, we'll just send the file name
            // You might want to handle file uploads separately
            jsonPayload[key] = value.name;
            console.warn("File upload should be handled separately");
          } else if (Array.isArray(value)) {
            jsonPayload[key] = value;
          } else if (typeof value === 'string') {
            jsonPayload[key] = value;
          }
        }
      });
      
      console.log("Submitting JSON payload:", jsonPayload);

      const response = await axios.put<{ success: boolean; message: string }>(
        "https://api.jackpass.io/users",
        jsonPayload,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${this.authenticatedToken}`,
          },
        }
      );

      console.log("Form submitted successfully:", response.data);
      // Handle success (e.g., show success message, navigate to next page)
    } catch (error) {
      console.error("Error submitting form:", error);
      this.submitError = "An error occurred while submitting the form. Please try again.";
    } finally {
      this.isSubmitting = false;
    }
  };
}

const signUpStore = new SignUpStore();
export default signUpStore;
