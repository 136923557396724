import React from "react";
import { Clock, Calendar } from "lucide-react";
import { Card, CardContent } from "../../shadcn-components/card";
import { Button } from "../../shadcn-components/button";

interface EventCardProps {
  imageUrl: string;
  title: string;
  time: string;
  date: string;
  slotsLeft: number;
  onRSVP: () => void;
}

export const EventCard: React.FC<EventCardProps> = ({
  imageUrl,
  title,
  time,
  date,
  slotsLeft,
  onRSVP,
}) => {
  const onRSVPClick = () => {
    // call rsvp request
    onRSVP();
  };
  return (
    <Card className="tw-overflow-hidden tw-border-custom-border">
      <img
        className="tw-w-full tw-h-48 tw-object-cover tw-rounded-t-lg tw-mb-3"
        src={imageUrl}
        alt={title}
      />
      <CardContent className="tw-p-4">
        <div className="tw-flex tw-justify-between tw-items-start tw-mb-2">
          <h2 className="tw-text-xl tw-font-bold">{title}</h2>
          <div className="tw-bg-yellow-100 tw-text-yellow-800 tw-px-2 tw-py-1 tw-rounded tw-text-sm">
            {slotsLeft} slots left
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-text-gray-600 tw-mb-1">
          <Clock className="tw-w-4 tw-h-4 tw-mr-2" />
          <span>{time}</span>
        </div>
        <div className="tw-flex tw-items-center tw-text-gray-600 tw-mb-4">
          <Calendar className="tw-w-4 tw-h-4 tw-mr-2" />
          <span>{date}</span>
        </div>
        <Button
          className="tw-w-full tw-bg-primary tw-text-white hover:tw-bg-blue-700"
          onClick={onRSVPClick}
        >
          RSVP
        </Button>
      </CardContent>
    </Card>
  );
};
