import "./App.css";
import { LoginSignUp } from "./components/google-auth";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SignUpForm } from "./components/signup-form";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Hub } from "./components/main-hub";
import { LandingPage } from "./components/landing";
import CreateCommunityForm from "./components/communities/create-community-form";
import Layout from "./components/layout";
import { ProfileEdit } from "./components/profile-edit";
import { CommunityView } from "./components/communities/community-profile-view";
import ManageCommunityForm from "./components/communities/manage-community";
import { ManageAdmins } from "./components/communities/manage-admins";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          
          {/* Protected/Hub routes */}
          {process.env.REACT_APP_GOOGLE_CLIENT_ID ? (
            <Route
              path="/*"
              element={
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                >
                  <Routes>
                    <Route path="join" element={<LoginSignUp />} />
                    <Route path="register" element={<SignUpForm />} />
                    <Route
                      path="*"
                      element={
                        <Layout>
                          <Routes>
                            <Route path="explore" element={<Hub view="events" />} />
                            <Route path="c" element={<Hub view="communities" />} />
                            <Route path="me" element={<ProfileEdit />} />
                            <Route path="c/new" element={<CreateCommunityForm />} />
                            <Route path=":communityHandle" element={<CommunityView />} />
                            <Route path=":communityHandle/manage" element={<ManageCommunityForm />} />
                            <Route path=":communityHandle/manage/admins" element={<ManageAdmins />} />
                            {/* Redirect to /explore if no match */}
                            <Route
                              path="*"
                              element={<Navigate to="/explore" replace />}
                            />
                          </Routes>
                        </Layout>
                      }
                    />
                  </Routes>
                </GoogleOAuthProvider>
              }
            />
          ) : (
            <Route path="*" element={<div>COMING SOON</div>} />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
