import { observer } from "mobx-react-lite";
import { FC } from "react";
import { CommunitiesHeaderSearch } from "./communities-header";

import { CommunityCard } from "./community-card";

export interface Community {
  id: number;
  name: string;
  handle: string;
  description: string;
  followers: string;
  profileImage: string;
  bannerImage: string;
  verified: boolean;
}

interface CommunitiesHomeProps {
  communities: Community[];
}

export const CommunitiesHome: FC<CommunitiesHomeProps> = observer(
  ({ communities }) => {
    return (
      <div className="tw-px-12">
        {" "}
        <CommunitiesHeaderSearch />
        <div className="tw-mt-12">
          <h2 className="tw-text-xl tw-font-semibold tw-mb-4 tw-text-left">
            My communities
          </h2>
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
            {communities.map((community) => (
              <CommunityCard key={community.id} community={community} />
            ))}
          </div>
        </div>
      </div>
    );
  }
);
