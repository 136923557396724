import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChevronLeft } from "lucide-react";
import { Button } from "../shadcn-components/button";
import { Input } from "../shadcn-components/input";
import { Progress } from "../shadcn-components/progress";
import { Label } from "@radix-ui/react-label";
import { ControllerRenderProps, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../shadcn-components/form";
import { SignUpInterests } from "./signup-interests";
import { PictureUpload } from "./profile-picture";
import signupStore, {
  formSchema,
  FormValues,
  SignUpReason,
} from "../stores/signup.store";
import { observer } from "mobx-react-lite";
import { SignUpCitySelector } from "./signup-city-selector";
import { Logo } from "./logo";
import { CalendarDatePicker } from "../shadcn-components/datepicker2";
import { PhoneInput } from "../shadcn-components/phone";
import { SignUpGenderSelection } from "./gender";
import { SignupReason } from "./signup-reason";
import { useNavigate } from "react-router-dom";

interface SignUpField {
  name: keyof FormValues;
  label: string;
  type: string;
  placeholder?: string;
  hint?: string;
}

const signUpFields: SignUpField[] = [
  {
    name: "name",
    label: "What's your name?",
    type: "text",
    placeholder: "Full Name",
    hint: "Let’s get to know each other, ngl, we’re very good with names ;)",
  },
  {
    name: "phone",
    label: "What's your phone number?",
    type: "tel",
    placeholder: "Your phone number",
  },
  {
    name: "location",
    label: "What's your location",
    type: "location",
    placeholder: "Allow access to your location",
  },
  {
    name: "profilePicture",
    label: "Add Profile Picture",
    type: "file",
    placeholder: "Choose a file",
    hint: "Time to put a face to the name. Add your fave picture",
  },
  {
    name: "dateOfBirth",
    label: "What's your date of birth?",
    type: "date",
    placeholder: "MM/DD/YYYY",
  },
  {
    name: "gender",
    label: "What's your gender?",
    type: "gender",
  },
  {
    name: "signUpReason",
    label: "What do you want to do on Jackpass?",
    type: "reason",
  },
  {
    name: "interests",
    label: "What are your interests?",
    type: "interests",
    placeholder: "Tell us about your hobbies and interests",
  },
];
type ErrorState = Partial<Record<keyof FormValues, { message: string }>>;
export const SignUpForm: FC = observer(() => {
  const totalSteps = signUpFields.length;
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(100 / totalSteps);
  const { handleSubmit: storeHandleSubmit, onPhoneNext } = signupStore;
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [errors, setErrors] = useState<ErrorState>({});
  const fieldRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      phone: "",
      profilePicture: undefined,
      dateOfBirth: undefined,
      interests: [],
      location: "",
    },
  });
  const formValues = useWatch({ control: form.control });

  const memoizedErrors = useMemo(() => {
    const formErrors = form.formState.errors;
    const newErrors: ErrorState = {};
    (Object.keys(formErrors) as Array<keyof FormValues>).forEach((key) => {
      const error = formErrors[key];
      if (error && "message" in error) {
        newErrors[key] = { message: error.message ?? "" };
      }
    });
    return newErrors;
  }, [form.formState.errors, formValues]);

  useEffect(() => {
    setErrors(memoizedErrors);
  }, [form.formState, memoizedErrors]);

  useEffect(() => {
    const updateLayout = () => {
      if (containerRef.current) {
        const currentField = fieldRefs.current[currentStep];
        if (currentField) {
          const height = currentField.offsetHeight;
          containerRef.current.style.height = `${height}px`;
        }
      }
    };

    updateLayout();
    const timer = setTimeout(updateLayout, 0);

    window.addEventListener("resize", updateLayout);
    return () => {
      window.removeEventListener("resize", updateLayout);
      clearTimeout(timer);
    };
  }, [currentStep]);

  const navigate = useNavigate();
  const handleNext = useCallback(async () => {
    const isLastStep = currentStep === signUpFields.length - 1;
    const currentFieldName = signUpFields[currentStep].name as keyof FormValues;

    const isCurrentFieldValid = await form.trigger(currentFieldName);

    if (!isCurrentFieldValid) {
      return;
    }

    if (isLastStep) {
      const isValid = await form.trigger();
      if (isValid) {
        const formData = form.getValues();
        await storeHandleSubmit(formData);
      } else {
        console.log("Form has errors:", form.formState.errors);
      }
      // navigate("/explore");
    } else {
      setIsAnimating(true);
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      setProgress((100 / totalSteps) * (nextStep + 1));

      setTimeout(() => {
        setIsAnimating(false);
        if (currentStep === 0 && nextStep === 1) {
          phoneInputRef.current?.focus();
        }
      }, 700);
    }
  }, [currentStep, form, storeHandleSubmit, totalSteps, navigate]);

  const handlePrevious = useCallback(() => {
    if (currentStep > 0) {
      setIsAnimating(true);
      const prevStep = currentStep - 1;
      setCurrentStep(prevStep);
      const revertProgress = 100 / totalSteps;
      setProgress((prev) => prev - revertProgress);

      setTimeout(() => {
        setIsAnimating(false);
      }, 700); // Match this with the animation duration
    }
  }, [currentStep, totalSteps]);

  const renderFormField = (field: SignUpField, index: number) => {
    return (
      <FormField
        key={field.name}
        control={form.control}
        name={field.name as keyof FormValues}
        render={({ field: formField }) => {
          const error = errors[field.name as keyof FormValues];
          return (
            <div
              ref={(el) => (fieldRefs.current[index] = el)}
              className={`tw-absolute tw-w-full tw-transition-all tw-duration-500 tw-ease-in-out
              ${
                index === currentStep
                  ? "tw-opacity-100 tw-visible"
                  : "tw-opacity-0 tw-invisible"
              }
              ${
                index === currentStep
                  ? "tw-translate-x-0"
                  : index < currentStep
                  ? "tw-translate-x-[-100%]"
                  : "tw-translate-x-[100%]"
              }`}
            >
              <FormItem className="tw-flex tw-flex-col tw-space-y-2">
                <FormLabel
                  className={`tw-text-xl tw-font-semibold${
                    error ? "tw-text-red-500" : ""
                  }`}
                >
                  {field.label}
                </FormLabel>
                {signUpFields[currentStep].hint && (
                  <Label className="tw-block tw-text-center tw-text-sm tw-text-gray-600 tw-mt-2 !tw-mb-6">
                    {signUpFields[currentStep].hint}
                  </Label>
                )}
                <FormControl>
                  {renderInput(field, formField, error)}
                </FormControl>
              </FormItem>
            </div>
          );
        }}
      />
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleNext();
    }
  };

  const autoAdvance = useCallback(() => {
    handleNext();
  }, [handleNext]);

  const renderInput = (
    field: SignUpField,
    formField: ControllerRenderProps<FormValues, keyof FormValues>,
    error?: { message?: string }
  ) => {
    const commonInputClasses =
      "tw-w-full md:tw-w-[520px] tw-p-3 !tw-rounded-[42px] tw-mx-auto";
      const errorMessage = error?.message || form.formState.errors[field.name]?.message;

    switch (field.type) {
      case "file":
        const image = form.getValues("profilePicture");
        return (
          <PictureUpload
            onImageUpload={(file) =>
              form.setValue("profilePicture", file ?? undefined)
            }
            currentImage={image ? image : null}
          />
        );
      case "tel":
        return (
          <div>
            <PhoneInput
              value={formField.value as string}
              onChange={(e) => {
                const numericValue = e.target.value;
                formField.onChange(numericValue);
                onPhoneNext(numericValue);
              }}
              className={`${commonInputClasses} ${
                errorMessage ? "tw-border-red-500" : ""
              }`}
              onKeyDown={handleKeyDown}
              ref={phoneInputRef}
            />
            {errorMessage && (
              <p className="tw-text-red-500 tw-text-sm tw-mt-1">
                {errorMessage}
              </p>
            )}
          </div>
        );
      case "date":
        return (
          <div className="tw-w-full">
            <CalendarDatePicker
              date={{ from: formField.value as Date }}
              onDateSelect={(range: { from: Date; to: Date }) => {
                formField.onChange(range.from);
              }}
              variant="secondary"
              numberOfMonths={1}
              className={`min-w-[250px] tw-p-3 !tw-rounded-[42px] tw-mx-auto ${
                errorMessage ? "tw-border-red-500" : ""
              }`}
              yearsRange={150}
              closeOnSelect
            />
            {errorMessage && (
              <p className="tw-text-red-500 tw-text-sm tw-mt-1 tw-text-center">
                {errorMessage}
              </p>
            )}
          </div>
        );
      case "gender":
        return (
          <div>
            <SignUpGenderSelection
              selectedGender={formField.value as string}
              handleSelect={(str) => {
                formField.onChange(str);
                autoAdvance();
              }}
            />
            {errorMessage && (
              <p className="tw-text-red-500 tw-text-sm tw-mt-1 tw-text-center">
                {errorMessage}
              </p>
            )}
          </div>
        );
      case "reason":
        return (
          <div>
            <SignupReason
              selectedOption={formField.value as SignUpReason}
              handleSelect={(option) => {
                formField.onChange(option);
                autoAdvance();
              }}
            />
            {errorMessage && (
              <p className="tw-text-red-500 tw-text-sm tw-mt-1 tw-text-center">
                {errorMessage}
              </p>
            )}
          </div>
        );
      case "interests":
        return (
          <div>
            <SignUpInterests
              selectedInterests={(formField.value as string[]) || []}
              onInterestsChange={(interests) => {
                formField.onChange(interests);
              }}
            />
            {errorMessage && (
              <p className="tw-text-red-500 tw-text-sm tw-mt-1 tw-text-center">
                {errorMessage}
              </p>
            )}
          </div>
        );
      case "location":
        return (
          <div>
            <SignUpCitySelector
              selectedCity={formField.value as string}
              onCityChange={(c) => {
                formField.onChange(c);
                autoAdvance();
              }}
              onLocationAccess={autoAdvance}
            />
            {errorMessage && (
              <p className="tw-text-red-500 tw-text-sm tw-mt-1 tw-text-center">
                {errorMessage}
              </p>
            )}
          </div>
        );
      default:
        return (
          <div>
            <Input
              type={field.type}
              placeholder={field.placeholder}
              value={formField.value as string}
              onChange={formField.onChange}
              className={`${commonInputClasses} ${
                errorMessage ? "tw-border-red-500" : ""
              }`}
              onKeyDown={handleKeyDown}
            />
            {errorMessage && (
              <p className="tw-text-red-500 tw-text-sm tw-mt-1">
                {errorMessage}
              </p>
            )}
          </div>
        );
    }
  };
  return (
    <Form {...form}>
      <div className="tw-flex tw-flex-col tw-items-center tw-min-h-screen tw-pt-10">
        <Logo className="tw-mb-16" />
        <div className="tw-w-full tw-max-w-[343px] md:tw-max-w-[520px] tw-relative">
          <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-mb-8">
            <Button
              size="icon"
              variant="secondary"
              onClick={handlePrevious}
              className="tw-absolute tw-left-[-16px] md:tw-left-[-51px] tw-p-0 tw-h-8 tw-w-8 md:tw-h-10 md:tw-w-10 tw-font-normal tw-text-gray-500 hover:tw-bg-transparent hover:tw-text-gray-700 !tw-rounded-full"
              disabled={currentStep === 0 || isAnimating}
            >
              <ChevronLeft className="tw-h-4 tw-w-4" />
            </Button>
            <Progress
              value={progress}
              className="!tw-w-[295px] md:!tw-w-[414px]"
            />
          </div>

          <div
            ref={containerRef}
            className="tw-w-full tw-overflow-hidden tw-relative tw-transition-all tw-duration-500 tw-ease-in-out"
          >
            {signUpFields.map((field, index) => renderFormField(field, index))}
          </div>

          <div className="tw-mt-8">
            <Button
              type="button"
              onClick={handleNext}
              className="tw-w-full md:tw-w-[272px] tw-mx-auto tw-block hover:tw-bg-blue-700 !tw-rounded-[38px]"
              disabled={isAnimating}
            >
              {currentStep === totalSteps - 1 ? "Finish" : "Next"}
            </Button>

            {currentStep === totalSteps - 1 && (
              <Label className="tw-block tw-text-center tw-text-sm tw-text-gray-600 tw-mt-8">
                Select between 1-5
              </Label>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
});
