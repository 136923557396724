import * as React from "react"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../../shadcn-components/command'
import { Avatar, AvatarImage, AvatarFallback } from '../../shadcn-components/avatar'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../shadcn-components/dialog'


const mockFollowers = [
  { name: "Jacob Jones", username: "robert", avatar: "https://github.com/shadcn.png" },
  { name: "Cody Fisher", username: "cody", avatar: "https://github.com/shadcn.png" },
  { name: "Kathryn Murphy", username: "kathy", avatar: "https://github.com/shadcn.png" },
  { name: "Floyd Miles", username: "floyd", avatar: "https://github.com/shadcn.png" },
  { name: "Devon Lane", username: "devon", avatar: "https://github.com/shadcn.png" },
  { name: "Eleanor Pena", username: "eleanor", avatar: "https://github.com/shadcn.png" },
]

export default function CommunityFollowers({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) {
  const [commandInput, setCommandInput] = React.useState<string>("")
  const [results, setResults] = React.useState(mockFollowers)

  React.useEffect(() => {
    setResults(
      mockFollowers.filter(follower => 
        follower.name.toLowerCase().includes(commandInput.toLowerCase()) ||
        follower.username.toLowerCase().includes(commandInput.toLowerCase())
      )
    )
  }, [commandInput])

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="tw-sm:max-w-[397px] tw-bg-white tw-rounded-sm !tw-p-2">
        <DialogHeader className="tw-px-2  tw-pb-0 tw-mb-0 tw-pt-1">
          <DialogTitle className="tw-flex tw-justify-between tw-items-center">
            <span className="tw-text-base tw-font-semibold">Community Followers</span>
          </DialogTitle>
        </DialogHeader>
        <Command className="tw-mt-0 !tw-py-0">
          <CommandInput 
            placeholder="Search followers..." 
            value={commandInput} 
            onValueChange={setCommandInput} 
            className="tw-focus:ring-0 tw-px-0 !tw-py-0 tw-text-base"
            />
          <CommandList>
            <CommandEmpty>No followers found.</CommandEmpty>
            <CommandGroup>
              {results.map((follower, index) => (
                <CommandItem key={`${follower.name}-${index}`} className="tw-flex tw-items-center tw-space-x-3 !tw-py-1 tw-px-1 hover:tw-bg-gray-100">
                  <Avatar className="tw-h-8 tw-w-8">
                    <AvatarImage src={follower.avatar} alt={follower.name} />
                    <AvatarFallback>{follower.name[0]}</AvatarFallback>
                  </Avatar>
                  <div className="tw-flex tw-items-center tw-space-x-2">
                    <span className="tw-text-sm tw-font-medium">{follower.name}</span>
                    <span className="tw-text-xs tw-text-gray-500">@{follower.username}</span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </DialogContent>
    </Dialog>
  );
}