import { FC, useEffect, useState } from "react";
import { Button } from "../shadcn-components/button";

import mumbaiIcon from "../static/mumbai.svg";
import delhiIcon from "../static/delhi.svg";
import bengaluruIcon from "../static/bengaluru.svg";
import hyderabadIcon from "../static/hyderabad.svg";
import chennaiIcon from "../static/chennai.svg";
import puneIcon from "../static/pune.svg";
import kolkataIcon from "../static/kolkata.svg";
import ahmedabadIcon from "../static/ahmedabad.svg";
import { MapPin } from "lucide-react";

interface City {
  name: string;
  imagePlaceholder: string;
}

interface CitySelectorProps {
  selectedCity: string | null;
  onCityChange: (city: string) => void;
  onLocationAccess: () => void;
}

export const SignUpCitySelector: FC<CitySelectorProps> = ({
  selectedCity,
  onCityChange,
  onLocationAccess,
}) => {
  const [isDetectingLocation, setIsDetectingLocation] = useState(false);

  const cities: City[] = [
    { name: "Mumbai", imagePlaceholder: mumbaiIcon },
    { name: "Delhi NCR", imagePlaceholder: delhiIcon },
    { name: "Bengaluru", imagePlaceholder: bengaluruIcon },
    { name: "Hyderabad", imagePlaceholder: hyderabadIcon },
    { name: "Chennai", imagePlaceholder: chennaiIcon },
    { name: "Pune", imagePlaceholder: puneIcon },
    { name: "Kolkata", imagePlaceholder: kolkataIcon },
    { name: "Ahmedabad", imagePlaceholder: ahmedabadIcon },
  ];

  useEffect(() => {
    const savedLocation = localStorage.getItem("userLocation");
    if (savedLocation) {
      const { city } = JSON.parse(savedLocation);
      onCityChange(city);
    }
  }, [onCityChange]);

  const detectLocation = () => {
    setIsDetectingLocation(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            // const response = await fetch(
            //   `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
            // );
            // const data = await response.json();
            // const detectedCity = data.city;

            // Save location data to localStorage
            localStorage.setItem(
              "userLocation",
              JSON.stringify({
                // city: detectedCity,
                lat: latitude,
                lon: longitude,
              })
            );
            onLocationAccess();
          } catch (error) {
            console.error("Error detecting location:", error);
            // alert("Failed to detect location. Please select a city manually.");
          } finally {
            setIsDetectingLocation(false);
            onLocationAccess();
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
          // alert("Unable to access your location. Please select a city manually.");
          setIsDetectingLocation(false);
          onLocationAccess();
        }
      );
    } else {
      alert(
        "Geolocation is not supported by your browser. Please select a city manually."
      );
      setIsDetectingLocation(false);
    }
  };

  const selectedStyle = (name: string) =>
    selectedCity === name
      ? "!tw-border-[#1E28FF] tw-border-1"
      : "!tw-border-[#EDEDEB]";
  return (
    <div className="tw-text-center tw-p-4">
    <p className="tw-text-gray-500 tw-mb-6 tw-text-sm sm:tw-text-base">
      We need it so we can show you all the communities nearby
    </p>
    <div className="tw-mx-auto tw-max-w-[524px]">
      <div className="tw-grid tw-grid-cols-4 tw-gap-2 sm:tw-gap-4">
        {cities.map((city) => (
          <Button
            key={city.name}
            variant="outline"
            className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-1 sm:tw-p-4 tw-h-[70px] sm:tw-h-24 tw-w-[83px] sm:tw-w-[119px] tw-rounded-lg tw-font-bold tw-text-xs sm:tw-text-sm ${selectedStyle(
              city.name
            )}`}
            onClick={() => onCityChange(city.name)}
          >
            <div className="tw-mb-1 sm:tw-mb-2">
              <img
                src={city.imagePlaceholder}
                alt={`${city.name} icon`}
                className="tw-w-8 tw-h-8 sm:tw-w-12 sm:tw-h-12"
              />
            </div>
            <span className="tw-leading-tight">{city.name}</span>
          </Button>
        ))}
      </div>
      <Button
        variant="secondary"
        className="tw-w-full sm:tw-w-[343px] !tw-rounded-[38px] tw-mt-6 tw-text-sm"
        onClick={detectLocation}
        disabled={isDetectingLocation}
      >
        <MapPin size={15} className="tw-mr-2" />
        Detect Location
      </Button>
    </div>
  </div>
  );
};
