import React from "react";
import { Sidebar } from "./sidenav";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight, Settings } from "lucide-react";
import { Button } from "../shadcn-components/button";
import profileSettingsStore from "../stores/profile-settings-store";
import { observer } from "mobx-react-lite";
import { communityStore } from "../stores/community-store";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = observer(({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSectionChange = (section: "events" | "communities") => {
    navigate(section === "events" ? "/explore" : "/c");
  };

  const handleProfileClick = () => {
    navigate("/me");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSettingsClick = () => {
    profileSettingsStore.openSettings();
  };

  const activeSection = location.pathname === "/c" ? "communities" : "events";

  const getHeaderInfo = () => {
    const path = location.pathname.slice(1); // Remove leading slash
    const pathParts = path.split("/");

    switch (true) {
      case path === "c/new":
        return { show: true, title: "Create a community" };
      case path === "me":
        return { show: true, title: "Your Profile", showSettingsIcon: true };
      case path === "explore":
      case path === "c":
        return { show: false, title: "" };
      case pathParts[1] === "manage":
        return {
          show: true,
          breadcrumbs: [
            { title: "Manage Community", path: `/${pathParts[0]}/manage` },
            { title: pathParts[2] === "admins" ? "Manage Admins" : undefined },
          ],
        };
      default:
        // Check if it's not a known route, assume it's a community handle
        if (!["join", "register"].includes(path)) {
          return { show: true, title: communityStore.currentCommunityViewName };
        }
        return { show: false, title: "" };
    }
  };

  const headerInfo = getHeaderInfo();

  return (
    <div className="tw-flex tw-h-screen">
      <Sidebar
        activeSection={activeSection}
        onSectionChange={handleSectionChange}
        onProfileClick={handleProfileClick}
      />
      <div className="tw-flex tw-flex-col tw-flex-1 tw-ml-[261px]">
        {headerInfo.show && (
          <header className="tw-flex tw-items-center tw-pt-12 tw-pl-12">
            <Button
              variant="secondary"
              size="icon"
              className="tw-mr-4 !tw-rounded-3xl"
              onClick={handleBackClick}
            >
              <ChevronLeft />
            </Button>
            {headerInfo.breadcrumbs ? (
              <div className="tw-flex tw-items-center">
                {headerInfo.breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && crumb.title && (
                      <ChevronRight className="tw-ml-4 tw-mr-4" />
                    )}
                    {index === headerInfo.breadcrumbs.length - 1 ? (
                      <span className="tw-text-xl tw-font-bold">
                        {crumb.title}
                      </span>
                    ) : (
                      <h1 className={`tw-text-xl tw-font-bold`}>
                        {" "}
                        {crumb.title}
                      </h1>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <h1 className="tw-text-xl tw-font-bold">{headerInfo.title}</h1>
            )}
            {headerInfo.showSettingsIcon && (
              <Button
                variant="secondary"
                size="icon"
                className="tw-ml-3 tw-rounded-3xl"
                onClick={handleSettingsClick}
              >
                <Settings size={24} />
              </Button>
            )}
          </header>
        )}
        <main className="tw-flex-1 tw-overflow-auto tw-p-6">{children}</main>
      </div>
    </div>
  );
});

export default Layout;
