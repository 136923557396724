import React, { FC, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/card";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "../../shadcn-components/avatar";
import { Button } from "../../shadcn-components/button";
import { Input } from "../../shadcn-components/input";
import { Trash2 } from "lucide-react";

interface Admin {
  id: string;
  name: string;
  avatarUrl: string;
}

const initialAdmins: Admin[] = [
  { id: "1", name: "Jacob Jones", avatarUrl: "https://github.com/shadcn.png" },
  {
    id: "2",
    name: "Brooklyn Simmons",
    avatarUrl: "https://github.com/shadcn.png",
  },
];

export const ManageAdmins: FC = () => {
  const [admins, setAdmins] = useState<Admin[]>(initialAdmins);
  const [newAdminUsername, setNewAdminUsername] = useState("");

  const handleDeleteAdmin = (id: string) => {
    setAdmins(admins.filter((admin) => admin.id !== id));
  };

  const handleAddAdmin = (e: React.FormEvent) => {
    e.preventDefault();
    if (newAdminUsername.trim()) {
      const newAdmin: Admin = {
        id: Date.now().toString(),
        name: newAdminUsername,
        avatarUrl: `https://i.pravatar.cc/150?u=${newAdminUsername}`,
      };
      setAdmins([...admins, newAdmin]);
      setNewAdminUsername("");
    }
  };

  return (
    <div className="tw-space-y-6 tw-max-w-md tw-mx-auto tw-p-6">
      <Card className="tw-bg-white tw-rounded-lg tw-border-custom-border">
        <CardHeader className="tw-my-0 tw-py-4 tw-px-4">
          <CardTitle className="tw-text-base tw-text-grey tw-text-left">
            Admin Details
          </CardTitle>
        </CardHeader>
        <CardContent className="tw-px-4 tw-pb-4">
          {admins.map((admin) => (
            <div
              key={admin.id}
              className="tw-flex tw-items-center tw-justify-between"
            >
              <div className="tw-flex tw-items-center tw-space-x-1">
                <Avatar className="!tw-h-5 !tw-w-5">
                  <AvatarImage src={admin.avatarUrl} alt={admin.name} />
                  <AvatarFallback className="tw-bg-gray-200 tw-text-gray-700">
                    {admin.name.charAt(0)}
                  </AvatarFallback>
                </Avatar>
                <span className="tw-text-gray-700 tw-text-base">
                  {admin.name}
                </span>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleDeleteAdmin(admin.id)}
                className="tw-text-red-500"
              >
                <Trash2 className="tw-h-3 tw-w-3" />
              </Button>
            </div>
          ))}
        </CardContent>
      </Card>

      <div>
        <h2 className="tw-text-base tw-font-semibold tw-mb-2 tw-text-left">Add new Admin</h2>
        <form onSubmit={handleAddAdmin} className="tw-space-y-2">
          <Input
            placeholder="@username"
            value={newAdminUsername}
            onChange={(e) => setNewAdminUsername(e.target.value)}
            className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-3xl"
          />
        </form>
      </div>
    </div>
  );
};
