import React, { useState, useRef, ChangeEvent, FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../shadcn-components/avatar";
import { Label } from "../shadcn-components/label";
import { Input } from "../shadcn-components/input";
import { X } from "lucide-react";
import { toast } from "../hooks/use-toast";

interface ProfilePictureUploadProps {
  onImageUpload: (file: File | null) => void;
  currentImage: File | null;
  size?: number;
}

export const PictureUpload: FC<ProfilePictureUploadProps> = ({ 
  onImageUpload, 
  currentImage, 
  size = 88 
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (currentImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target?.result as string);
      };
      reader.readAsDataURL(currentImage);
    } else {
      setImageUrl(null);
    }
  }, [currentImage]);

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target?.result) {
            setImageUrl(e.target.result as string);
            onImageUpload(file);
          }
        };
        reader.readAsDataURL(file);
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload an image file.",
          variant: "destructive",
        });
        resetToFallback();
      }
    }
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    resetToFallback();
  };

  const resetToFallback = () => {
    setImageUrl(null);
    onImageUpload(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-space-y-4 tw-max-w-sm tw-mx-auto">
      <div className="tw-relative">
        <div 
          className="tw-w-[88px] tw-h-[88px] tw-rounded-full tw-overflow-hidden tw-cursor-pointer"
          onClick={handleImageClick}
        >
          {imageUrl ? (
            <img 
              src={imageUrl} 
              alt="Profile picture" 
              className="tw-w-full tw-h-full tw-object-cover"
            />
          ) : (
            <div className="tw-w-full tw-h-full tw-bg-gray-200 tw-flex tw-items-center tw-justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-8 tw-w-8 tw-text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </div>
          )}
        </div>
        {imageUrl && (
          <button
            className="tw-absolute tw-top-[8px] tw-right-[12px] tw-transform tw-translate-x-1/2 tw-translate-y-[-50%] tw-bg-white tw-border tw-border-gray-300 tw-rounded-full tw-p-1 tw-text-gray-600 hover:tw-bg-gray-100 tw-transition-colors"
            onClick={handleRemoveImage}
          >
            <X size={16} />
          </button>
        )}
      </div>

      <input
        type="file"
        ref={fileInputRef}
        className="tw-hidden"
        onChange={handleImageUpload}
        accept="image/*"
      />
    </div>
  );
};