import { Button } from "../shadcn-components/button";
import { allInterests } from "../utils/interests-utils";
import { FC, useState } from "react";
import { SquarePen } from "lucide-react";
import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "./error-boundary";
import { PictureUpload } from "./profile-picture";
import ProfileSettings from "./profile-settings";
import profileSettingsStore from "../stores/profile-settings-store";
import { useNavigate } from "react-router-dom";
import { CustomDropdownWithIcon } from "../shadcn-components/custom-dropdown-with-icon";
import { CustomTextareaWithIcon } from "../shadcn-components/custom-textarea-with-icon";

const cities = [
  "Delhi NCR",
  "Bangalore",
  "Hyderabad",
  "Mumbai",
  "Chennai",
  "Pune",
];
export const ProfileEdit: FC = observer(() => {
  const [location, setLocation] = useState("Delhi NCR");
  const [bio, setBio] = useState("sassy bio 123");
  const [interests, setInterests] = useState(allInterests.slice(0, 3));
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [isCitySelectorOpen, setIsCitySelectorOpen] = useState(false);
  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
  const { isSettingsOpen } = profileSettingsStore;

  const navigate = useNavigate();

  const handleLocationChange = (city: string) => {
    setLocation(city);
  };

  const handleBioChange = (value: string) => {
    setBio(value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleInterestsChange = (newInterests: string[]) => {
    // setInterests(newInterests);
    setIsInterestsModalOpen(false);
  };

  return (
    <div className="tw-min-h-screen tw-p-4">
      <div className="tw-max-w-[400px] tw-mx-auto">
        {isSettingsOpen ? (
          <ProfileSettings
            onClose={() => profileSettingsStore.closeSettings()}
          />
        ) : (
          <div className="tw-space-y-6">
            <div className="tw-flex tw-justify-between tw-items-center">
              <h2 className="tw-text-2xl tw-mb-4 tw-text-grey">
                Profile Details
              </h2>
            </div>
            <div className="tw-mb-6">
              <PictureUpload onImageUpload={() => {}} currentImage={null} size={88}/>
            </div>
            <div>
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                <label className="tw-text-sm tw-font-medium tw-text-grey">
                  Location
                </label>
              </div>
              <ErrorBoundary>
                <CustomDropdownWithIcon
                  options={cities}
                  value={location}
                  onChange={handleLocationChange}
                  onSelect={() => {}} // You can add any additional logic here if needed
                />
              </ErrorBoundary>
            </div>

            {/* Bio section */}
            <div className="tw-relative">
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                <label className="tw-text-sm tw-font-medium tw-text-grey">
                  Bio
                </label>
              </div>
              <CustomTextareaWithIcon
                value={bio}
                onChange={handleBioChange}
                disabled={!isEditingBio}
                className="tw-min-h-[100px] tw-w-full tw-p-2 tw-rounded-md"
                onIconClick={() => setIsEditingBio(!isEditingBio)}
              />
            </div>

            {/* Interests section */}
            <div>
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                <label className="tw-text-sm tw-font-medium tw-text-grey">
                  Interests
                </label>
                <Button
                  variant="link"
                  size="icon"
                  onClick={() =>
                    setIsInterestsModalOpen(!isInterestsModalOpen)
                  }
                  className="tw-p-0 hover:tw-bg-transparent"
                >
                  <SquarePen className="tw-h-3 tw-w-3 tw-text-grey" />
                </Button>
              </div>
              <div className="tw-flex tw-flex-wrap tw-gap-2">
                {interests.map((interest) => (
                  <Button
                    key={interest.name}
                    variant="outline"
                    className="tw-cursor-default tw-px-3 tw-py-1 tw-text-sm tw-rounded-full"
                    disabled={!isInterestsModalOpen}
                  >
                    {interest.emoji} {interest.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
