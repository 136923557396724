import { FC } from "react";
import logo from "../static/logo.png";
import logoText from "../static/logo-blacktext.svg";
import { cn } from "../lib/utils";
interface LogoProps {
    className:string;
}
export const Logo: FC<LogoProps> = ({className}) => {
  return (
    <div className={cn("tw-flex tw-items-center tw-justify-center tw-h-14", className)}>
      <img src={logo} alt="Jackpass logo" className="tw-w-8 tw-h-8" />
      <img src={logoText} alt="Jackpass" className="tw-h-8 tw-ml-2" />
    </div>
  );
};
