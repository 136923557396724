import { action, makeAutoObservable, observable } from 'mobx';

interface CommunityFormData {
  communityName: string;
  username: string;
  communityDescription: string;
}

class CommunityStore {

  @observable communityData = {
    communityName: "Bhag Club",
    communityUsername: "@bhagclub",
    bio: "Bhag Club is India's epiciest run club, with group runs every weekend across Delhi NCR",
    location: "Delhi NCR",
    category: "Fitness",
    twitter: "https://x.com/settings/profile",
    instagram: "https://x.com/settings/profile",
    youtube: "https://x.com/settings/profile",
  };

  @observable currentCommunityViewName: string = 'community name';
  constructor() {
    makeAutoObservable(this);
  }

  createCommunity(data: CommunityFormData) {
    // Implementation for creating the community
    console.log('Creating community:', data);
    // Perform API call or other logic here
    // Then maybe reset form or navigate to next step
  }

  @action
  setCurrentCommunityViewName(viewName: string) {
    this.currentCommunityViewName = viewName
  };

  @action
  fetchCommunityData = (id:string) => {

  };
}

export const communityStore = new CommunityStore();